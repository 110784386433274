<template>
  <div class="score-bar" :style="barStyle">
    <div class="filled-bar" :style="filledBarStyle"></div>
  </div>
</template>

<script>
export default {
  props: {
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    },
    value: Number,
    backgroundColor: {
      type: String,
      default: "#bdbdbd",
    },
    barColors: {
      type: Array,
      default() {
        return ["#ff4838", "#ffe331", "#c7ff31", "#5fd90c"];
      },
    },
    reverse: Boolean
  },
  data(){
    return {
        colorMap: this.buildColorMap()
    }
  },
  methods: {
    buildColorMap(){
      const numColors = this.barColors?.length;
      let result = [];
      if (numColors > 0) {
        const percentInterval = 1 / numColors;
        let currentMinimum = 0;

        for (let i = 0; i < numColors; i++) {
          result.push({
            color: this.reverse == true ? this.barColors[numColors - 1 - i] : this.barColors[i],
            min: currentMinimum,
            max:
              i == numColors - 1
                ? 1
                : currentMinimum + percentInterval - 0.00000000001,
          });
          currentMinimum += percentInterval;
        }
      } else {
        result = [
          {
            color: "black",
            min: 0,
            max: 1,
          },
        ];
      }
      return result;
    }
  },
  computed: {
    barPercentage() {
      return (this.value - this.min) / this.range;
    },
    range() {
      return this.max - this.min;
    },
    valueColor() {
      return this.colorMap.find((x) => {
        return this.barPercentage >= x.min && this.barPercentage <= x.max;
      })?.color;
    },
    barStyle(){
        return {
            backgroundColor: this.backgroundColor
        }
    },
    filledBarStyle(){
        return {
            width: this.barPercentage * 100 + '%',
            backgroundColor: this.valueColor
        }
    }
  },
};
</script>

<style lang="scss" scoped>

    .score-bar, .filled-bar {
        height: 13px;
    }

    .score-bar {
        border-radius: 6px;
        width: 100%;
    }

    .filled-bar {
        border-radius: 5px 0 0 5px;
        height: 13x;
    }
</style>
